.wrapper {
  width: 60%;
  margin: 0 auto;
}

.wrapper h1 {
  font-size: 5rem;
  color: deeppink;
}

.error {
  color: red;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 90%;
  }
}
