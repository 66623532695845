.sentence {
  margin: 2rem 0;
}

.data {
  margin: 2rem auto;
  width: 70%;
  text-align: left;
  font-size: 1.5rem;
}
