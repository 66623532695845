.wrapper button,
.wrapper span {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 4rem;
  color: dodgerblue;
}

.wrapper button {
  margin: 0.5rem;
  cursor: pointer;
}

.wrapper button:hover {
  color: hotpink;
}

.wrapper .punctuation {
  margin-left: -.6rem;
}
