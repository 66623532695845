.App {
  text-align: center;
}

.appButton {
  margin: 1rem 1.2rem;
  padding: 1rem 2rem;
  background-color: transparent;
  border: 2px solid cornflowerblue;
  border-radius: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: slategray;
  cursor: pointer;
  text-transform: uppercase;
}

.appButton:hover,
.appButton:active,
.appButton:focus {
  transform: rotate(3deg) scale(1.1);
  color: cornflowerblue;
}

.appButton:disabled {
  background-color: ghostwhite;
  border-color: slategrey;
  color: slategrey;
  cursor: auto;
}

.appButton:disabled:hover {
  transform: none;
}
